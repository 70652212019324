import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  display: "flex",
  flexDirection: "collum",
  position: "absolute",
  top: "50%",
  left: "50%",
  justifyContent: "center",
  alingItem: "center",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ModalComponent({ open, close }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              position: "absolute",
              right: 25,
              top: 10,
            }}
          >
            <IconButton
              onClick={close}
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <FormControl variant="standard">
            <Typography variant="h6" gutterBottom component="div">
              Editar contato
            </Typography>

            <TextField
              id="demo-helper-text-misaligned"
              label="Nome"
              style={{ marginBottom: 15 }}
            />
            <TextField
              id="demo-helper-text-misaligned"
              label="Número"
              style={{ marginBottom: 15 }}
            />
            <Button variant="contained">Salvar</Button>
          </FormControl>
        </Box>
      </Modal>
    </div>
  );
}
