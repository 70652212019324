import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ImportContacts from "./index";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import NewGroup from "./newGroup";

import Group from "./groups";
import ListContacts from "./ContactsLists";

const style = {
  display: "flex",
  flexDirection: "collum",
  position: "absolute",
  top: "50%",
  left: "50%",
  justifyContent: "center",
  alingItem: "center",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  //border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ModalComponent({ open, close }) {
  const [openModal, closeModal] = React.useState(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <NewGroup open={openModal} close={() => closeModal(!openModal)} />

          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12}>
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  right: 25,
                  padding: 10,
                  top: 0,
                }}
              >
                <IconButton
                  onClick={close}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <Paper
                style={{ padding: 10, marginTop: 20 }}
                variant="outlined"
                square
              >
                <ImportContacts />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper style={{ height: 400 }} variant="outlined" square>
                <Group open={() => closeModal(!openModal)} />
              </Paper>
            </Grid>
            <Grid item xs={8}>
              <ListContacts />
            </Grid>
            <Grid item xs={12} spacing={2}>
              <Button
                onClick={close}
                variant="contained"
                style={{ backgroundColor: "red" }}
              >
                Cancelar
              </Button>

              <Button style={{ marginLeft: 10 }} variant="contained">
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
